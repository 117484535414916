// Tabs
.tabs {
  position: relative;
  padding: 8px 24px;
  border: 1px solid $color-dark-blue;
  text-transform: capitalize;
  text-decoration: none;
  transition: 0.5s ease-out;
  border-radius: 10px;
  color: $color-dark-blue;
  font-weight: bold;

  &.active {
    background: $color-dark-blue;
    color: $color-white;
  }

  label {
    font-weight: bold;
    cursor: pointer;
  }

  &:hover {
    background: $color-dark-blue;
    color: $color-white;
  }
}

// Tabs Navigaton
.tabs-nav {
  position: relative;
  .tabs-nav-wrap {
    width: 100%;
    height: 64px;
    border-radius: 10px;
    transition: 0.5s ease-out;
    cursor: pointer;
    padding: 8px;
    gap: 12px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.active {
      background: $color-extra-light-blue;
      font-weight: bold;
    }

    &:hover {
      background: $color-extra-light-blue;
      font-weight: bold;
    }
  }

  label {
    font-size: 18px;
    color: $color-black;
    line-height: 1.35;
    text-transform: capitalize;
    cursor: pointer;
  }
}
