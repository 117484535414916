// Background Settings
.header-bg {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-size: cover;
  background-position: left bottom;

  &--v1,
  &--v2,
  &--home &--v1 {
    background-image: url("../../media/images/header-bg/header-bg-t1.svg");
  }

  &--v2 {
    background-image: url("../../media/images/header-bg/article-header.svg");
  }

  &--home {
    background-image: url("../../media/images/header-bg/header-bg-navy.svg");
  }

  // Used by Sign In, Sign Up, Forgot Password, Reset Password, Email Confirmation, 404, Coming Soon
  &--full-screen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: 100% 0;
    background-repeat: no-repeat no-repeat;
    background-image: url("../../media/images/header-bg/bg-utilites-pages.svg");
    background-size: cover;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(xl) {
      background-position: center center;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  // Height Size
  &--short {
    height: 690px;

    @include media-breakpoint-down(md) {
      height: 809px;
    }
  }
}
