// Header Used by ChangeLog and License
.header {
  position: relative;
  z-index: 1;
  overflow: hidden;

  &--mini {
    background-color: $color-extra-light-blue;

    .pattern {
      position: absolute;
      z-index: -1;
      width: 2578px;
      height: 848.5px;
      background-image: url('../../media/images/patterns-and-ornaments/license-pattern.svg');
      background-size: contain;
      background-position: center;
      top: toRem(-229);
      left: toRem(-337);
    }

    .container {
      max-width: 625px;
      display: flex;
      flex-direction: column;
      padding-top: toRem(104);
      padding-bottom: toRem(104);
      align-items: center;
      justify-content: center;
      gap: toRem(16);
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
      }

      h1 {
        color: $color-dark-deep-blue;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: toRem(60);
        }
      }

      p {
        color: $color-grey;
        margin-bottom: 0;
      }
    }
  }

  &--large {
    background-color: $color-extra-light-blue;

    .pattern {
      position: absolute;
      z-index: -1;
      width: 2578px;
      height: 848.5px;
      background-image: url('../../media/images/patterns-and-ornaments/license-pattern.svg');
      background-size: contain;
      background-position: center;
      top: toRem(-229);
      left: toRem(-337);
    }

    .container {
      padding-top: toRem(104);
      max-width: 625px;
      display: flex;
      flex-direction: column;
      gap: toRem(36);
      align-items: center;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding-top: toRem(64);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
      }

      .text-wrap {
        display: flex;
        flex-direction: column;
        gap: toRem(16);
        align-items: center;

        .text__head {
          display: flex;
          flex-direction: column;
          gap: toRem(16);
          align-items: center;

          p {
            color: $color-black;
          }

          h1 {
            color: $color-dark-deep-blue;
          }
        }
      }

      .btn-wrap {
        display: flex;
        flex-direction: row;
        gap: toRem(16);
        align-items: center;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          width: 100%;
        }

        & > * {
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
      }
    }
  }

  &--link {
    padding: toRem(32);
    border-radius: 10px;
    box-shadow: $shadow-md;
    border: solid 1px $color-light-grey;

    @include media-breakpoint-down(sm) {
      padding: toRem(20);
    }

    .header-content {
      display: flex;
      flex-direction: column;
      gap: toRem(20);
      background-color: $color-extra-light-deep-blue;
      border-radius: 10px;
      padding: toRem(24);

      @include media-breakpoint-down(sm) {
        padding: toRem(16);
        gap: toRem(16);
      }

      .text-wrap {
        display: flex;
        flex-direction: column;
        gap: toRem(8);

        h2 {
          color: $color-dark-deep-blue;
        }
      }

      .link-wrap {
        display: flex;
        flex-direction: row;
        gap: toRem(8);
        align-items: center;

        .header-link {
          color: $color-black;
          transition: all 0.2s;

          &:hover {
            color: $color-black;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
